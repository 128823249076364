import React from 'react'
import toolbarStyle from './toolbar.module.css'
import {SocialLinksBar} from 'components/social'
import {useTopToolbarMenu, ProfileMenu} from 'components/menus'
import {Link} from 'components/link'

export const TopToolbar =  (props) => {
  const menu = useTopToolbarMenu();
  return(
    <div id="toptoolbar" className={`${toolbarStyle.top_toolbar}`}>
      <div className={toolbarStyle.item}>
        <SocialLinksBar/>
      </div>
      <div id="toptoolbar_menu" className={toolbarStyle.top_toolbar__menu}>
        {menu.map((item, index)=><Link to={item.url} key={`${item.url}-${index}`} className={toolbarStyle.text}>{item.title}</Link>)}
        <ProfileMenu className="ml-5"/>
      </div>

    </div>
)
}
