import React from 'react'
import { useSocialLinks } from './hook'
import { Link } from 'components/link';
import socialStyles from './socialbar.module.css'
import { ReactComponent as YoutubeIcon } from 'images/icons/youtube.svg'
import { ReactComponent as FacebookIcon } from 'images/icons/facebook.svg'
import { ReactComponent as TwitterIcon } from 'images/icons/twitter.svg'
import { ReactComponent as LinkedinIcon } from 'images/icons/linkedin.svg'
import { ReactComponent as InstagramIcon } from 'images/icons/instagram.svg'

const networkToIcon = {
	twitter: TwitterIcon,
	facebook: FacebookIcon,
	linkedin: LinkedinIcon,
	youtube: YoutubeIcon,
	instagram: InstagramIcon
}

export const SocialLinksBar = (props) => {
	const links = useSocialLinks();
	return (
		<div className={socialStyles.wrapper}>
			{links.map(
				link =>
					<Link key={link.network} className={`${socialStyles.network} ${socialStyles[link.network]} ${props.iconClassName ? props.iconClassName : ''}`} to={link.url} target="_blank">
						{React.createElement(networkToIcon[link.network], { className: "fill-current" })}
					</Link>)
			}
		</div>
	)
}
