import React from 'react'
import {ContentCard} from './card'
import {ReactComponent as DownloadIcon} from 'images/icons/download_arrow.svg'


export const MediaCard =  (props) => (
  <ContentCard
    {...props}
    icon={(<DownloadIcon className="fill-current stroke-current text-iconblue" />)}
  />
)





