import React from 'react'
import {FieldWrapper} from './wrapper'

export const InputField = (props) => {
  return(
    <FieldWrapper {...props}>
      <input
        type={props.type}
        placeholder={props.placeholder}
        style={props.style}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className="input appearance-none bg-transparent border-none w-full  text-grey-darker mr-3  leading-tight focus:outline-none"
      />
    </FieldWrapper>
  )
};
