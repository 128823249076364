import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import PublicationCard from 'components/cards'
import ResultsStyle from './searchresults.module.css'


const withNormalizedResults = (results)=>{
  const data = useStaticQuery(graphql`
    query normalizedSearchResultsByType{
      page:allWordpressPage{
        nodes{
          id
          wordpress_id
          path
          title
          excerpt
        }
      }
      posts:allWordpressPost{
        nodes{
          id
          wordpress_id
          path
          title
          excerpt
        }
      }
      news:allWordpressWpNews{
        nodes{
          id
          wordpress_id
          path
          title
          excerpt
          featured_media {
            localFile{
              ...ContentCardImages
            }
          }
        }
      }
      crops:allWordpressWpCrops{
        nodes {
          id
          wordpress_id
          path
          title
          excerpt
          featured_media{
            localFile{
              ...ContentCardImages
            }
          }
        }
      }
      collaboration:allWordpressWpCollaboration{
        nodes {
          id
          wordpress_id
          path
          title
          excerpt
          featured_media{
            localFile{
              ...ContentCardImages
            }
          }
        }
      }
      members:allWordpressWpMembers{
        nodes {
          id
          wordpress_id
          path
          title
          excerpt
          featured_media{
            localFile{
              ...ContentCardImages
            }
          }
        }
      }
      people:allWordpressWpPeople {
        nodes {
          id
          wordpress_id
          path
          title
          excerpt
          featured_media{
            localFile{
              ...ContentCardImages
            }
          }
        }
      }
      topics:allWordpressWpTopics{
        nodes {
          id
          wordpress_id
          path
          title
          excerpt
          featured_media{
            localFile{
              ...ContentCardImages
            }
          }
        }
      }
      vbs:allWordpressWpVbs {
        nodes {
          id
          wordpress_id
          path
          title
          excerpt
          featured_media{
            localFile{
              ...ContentCardImages
            }
          }
        }
      }
      resources:allWordpressWpResources{
        nodes {
          id
          wordpress_id
          path
          title
          excerpt
          isDownloadable
          link{
            url
            target
          }
          featured_media{
            localFile{
              ...ContentCardImages
            }
          }
        }
      }
    }
`)
  let _results = []
  results.forEach(r => {
    if(data.hasOwnProperty(r.type) && data[r.type].nodes.length){
      const match = data[r.type].nodes.find(i=>i.wordpress_id===r.wordpress_id)
      if(match){
        _results.push( {
          ...match,
          content: match.excerpt,
          image: match.featured_media?match.featured_media.localFile.childImageSharp:null,
        link: {
          url: match.path,
          target: match.link && match.link.hasOwnProperty("target")?match.link.target:""
        }
        });
      }
    }
  })
  return _results;
}

const NoResults = ({query}) => (
  <p className={ResultsStyle.noResults}>We couldn’t find anything about « {query} »</p>
)

export const SearchResults = (props) => {
  const results = withNormalizedResults(props.results);
  return (
    <div className={ResultsStyle.container}>
      {
         results.length === 0 ?
          <NoResults query={props.query}/>
        :
        results.map((item, index) => (
      <div className="my-2 w-full" key={item.id}>
        <PublicationCard {...item} />
      </div>
        ))
      }
    </div>
  );
}
