
import {useStaticQuery, graphql} from "gatsby"

export const useTopToolbarMenu = () => {
  const {menu} = useStaticQuery(graphql`
    query TopToolbarMenuItems{
      menu:wordpressWpApiMenusMenusItems(name:{eq:"top_toolbar"}){
        items{
          title
          url
          object_slug
          wordpress_parent
          wordpress_id
        }
      }
    }

    `)
  return menu.items;
}

