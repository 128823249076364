
import {useStaticQuery, graphql} from "gatsby"
import {buildHierarchy} from 'helpers'

export const useMainMenu = () => {
  const {menu} = useStaticQuery(graphql`
    query MainMenuItems{
      menu:wordpressWpApiMenusMenusItems(name:{eq:"main"}){
        items{
          title
          url
          object_slug
          wordpress_parent
          wordpress_id
        }
      }
    }

    `)
  return menu && menu.items && menu.items.length > 0 ? buildHierarchy(menu.items,'wordpress_id', 'wordpress_parent'):[];
}

