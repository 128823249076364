import React from "react"
import {Link} from 'components/link'
import DropDownStyles from './dropdown.module.css'
import MenuItemsStyles from './menuitem.module.css'
import {ReactComponent as ChevronIcon} from 'images/icons/Chevron.svg'


const MenuItem = (props) => {
  const depth = props.depth?props.depth:0
  return(
    <Link to={props.url} key={props.url} className={`${MenuItemsStyles["depth"+depth]}`} >
	<span dangerouslySetInnerHTML={{__html:props.title}} />
    </Link>
  )
}

const DropDown = (props) => {
  const depth = props.depth?props.depth:0;
  const [isOpen, setOpen] = React.useState(false)
  return (
    <div className={DropDownStyles["depth"+depth]}>
      <div className={DropDownStyles.header}>
        <MenuItem {...props} depth={depth}/>
        {props.items && props.items.length > 0 && depth <= 2 &&
         <button onClick={()=>setOpen(!isOpen)}>
           <ChevronIcon className={isOpen?DropDownStyles.open:''}/>
         </button>
        }
      </div>
      {isOpen && props.items && props.items.length > 0  && depth <=2  &&
       <div className={DropDownStyles.content}>
         {
           props.items.map(item =>{

               return (<DropDown key={item.slug} {...item} depth={depth+1}/>)
           })
         }
       </div>
      }
    </div>
  )
}
export const DropDownMenu = (props) => {

  return (
    <div  className={`${props.className} ${DropDownStyles.wrapper}` }>
      <DropDown {...props.entry}/>
    </div>
  )
}
