import React from 'react'
import {Link as GatsbyLink} from 'gatsby';


// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
export const Link = ({ children, to, activeClassName,target, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const backend_url = `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}`
  let _to = to.toString();
  _to = _to.replace(backend_url, '')

  const target_blank = target && target==='_blank'
  const internal = _to?!/^https?:\/\//.test(_to):false;

  // Use Gatsby Link for internal links, and <a> for others
  if (internal && !target_blank) {
    return (
      <GatsbyLink to={_to} target={other.target} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
   
    <a href={to} target='_blank' rel="noopener noreferrer" {...other} >
      {children}
    </a>
  );
};
