import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
export default (props) => {
  const {data} = useStaticQuery(graphql`
    query CorporateIdentity {
      data:wordpressAcfOptions{
      options{
        email:contact_email,
        name:corporate_name,
        phone: phone,
        address: postal_address
        }
        }
    }

    `)
  return (
    <div id="contact_infos" className="flex flex-wrap justify-center text-center">
      <p className="mx-5" dangerouslySetInnerHTML={{__html:data.options.address}}></p>
      <a className="mx-5" href={'mailto:'+data.options.email}>{data.options.email}</a>
      <a className="mx-5" href={'tel:' + data.options.phone}>{data.options.phone}</a>
    </div>
  )

}
