import React from 'react'
import CardStyle from './publication.module.css'
import {Link} from 'components/link'
import {navigate} from 'gatsby'
import {ReactComponent as ChevronIcon} from 'images/icons/arrow.svg'
import PropTypes from 'prop-types';
import Img from "gatsby-image/withIEPolyfill"
import {sliceExcerpt} from 'helpers'
import {MediaQuery} from 'react-responsive'

export const ContentCard =  (props) => (
  
  <div
    id={`contentCard-${props.id}`}
    className={CardStyle.card}
  >

    <div className={CardStyle.overlay}/>
    <div className={CardStyle.wrapper}>
      {props.showThumbnail && props.image &&
       
       <div className={CardStyle.image}>
         <>
         <MediaQuery query="(min-width: 768px)">
         <Img
           fixed={props.image.fixed}
           objectFit="cover"
           objectPosition="50% 50%"
         />
         </MediaQuery>
           <MediaQuery query="(max-width: 768px)">
           <Img
             fluid={props.image.fluid}
             style={{height: props.image.fluid.presentationHeight}}
           />
         </MediaQuery>
       </>
       </div>
       
      }
      <div className={CardStyle.content}>
        <div className={CardStyle.title} dangerouslySetInnerHTML={{__html: props.title}}/>
        <div dangerouslySetInnerHTML={{__html: sliceExcerpt(props.content)}}/>
      </div>
      <div className={CardStyle.actions}>
        <Link to={props.link.url} target={props.link.target}>
          <button>
            {props.icon}
          </button>
        </Link>
      </div>
    </div>
  </div>
)

ContentCard.propTypes = {
  showThumbnail: PropTypes.bool
}

ContentCard.defaultProps = {
  showThumbnail: true
}
