
import {useStaticQuery, graphql} from "gatsby"
import {buildHierarchy} from 'helpers'

export const useFooterMenu = () => {
  const {menu} = useStaticQuery(graphql`
    query FooterMenuItems{
      menu:wordpressWpApiMenusMenusItems(name:{eq:"footer"}){
        items{
          title
          url
          object_slug
          wordpress_parent
          wordpress_id
        }
      }
    }

    `)
  
  return menu.items;
}

