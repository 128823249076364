import React from "react";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';

export default props => <Yoast {...props} />;

const Yoast = ({ yoast }) => {
    if (!yoast ) return null;

	return (
		<Helmet>
		  {parse (yoast)}
		</Helmet>
	);
};
