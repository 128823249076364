import React, { useState } from 'react'
import {Link} from 'components/link'
import {ReactComponent as Petals} from 'images/icons/Seeds-Small.svg'

import './style.css'

export const ReadMoreButton = ({link, fontColor, symbolColor, className, textClass, showSymbol, text}) => {
    let [hovered, setHovered] = useState(false)
    let classes=''
    let _textClass=''
    let _text = text?text:'Read more'

    if(className){
        classes=className
    }
    if(textClass){
        _textClass = textClass
    }
    if(!showSymbol){
        showSymbol=false
    }

    return (
        <div className={`read-more__container ${classes}`}
             onMouseEnter={() => setHovered(true)}
             onMouseLeave={() => setHovered(false)}
        >
          <Link
            to={link.url}
            title={link.title}
            target={link.target}
            style={{color: fontColor}}
            className='read-more__text'
          >
            {(hovered || showSymbol) &&
             <div className='read-more__background'>
               <Petals style={{color: symbolColor?symbolColor:'#AAC939'}} className='fill-current read-more__petals' />
             </div>
            }
            <span className={`${_textClass}`}>{_text}</span>
          </Link>

        </div>
    );
};

export const RevealButton = ({children, fontColor, symbolColor, className, textClass, showSymbol, text}) => {
	let [hovered, setHovered] = useState(false)
	const [reveal, setReveal] = useState(false)
    let classes=''
    let _textClass=''
    let _text = text?text:'Read more'

    if(className){
        classes=className
    }
    if(textClass){
        _textClass = textClass
    }
    if(!showSymbol){
        showSymbol=false
    }

    return (
        <div className={`read-more__container ${classes}`}
             onMouseEnter={() => setHovered(true)}
             onMouseLeave={() => setHovered(false)}
             onClick={(e)=>{ e.stopPropagation(); if(!reveal){setReveal(true)}}}
        >
          {
              reveal
                  ?<>{children}</>
              :
              <>
                {

                    (hovered || showSymbol) &&
                        <div className='read-more__background'>
                          <Petals style={{color: symbolColor?symbolColor:'#AAC939'}} className='fill-current read-more__petals' />
                        </div>
                }
                <span className={`${_textClass}`}>{_text}</span>
              </>
          }

        </div>
    );
};

export const Button =  ({className, ...props}) => (
    <Link className={`button ${className?className:''}`}  {...props} />
)

export const ButtonNoLink =  (props) => (
    <button className='button' {...props} />
)

export const WhiteButton = (props) => (
    <button className='white-button' {...props} />
)
