import React, {useState} from 'react'
import SiteLogo from 'components/sitelogo'
import {useMainMenu} from 'components/menus'
import {Link} from 'components/link'
import {TopToolbar} from 'components/toolbars'
import HeaderStyles from './mobileheader.module.css'
import {SearchBar} from 'components/search'
import {DropDownMenu} from 'components/menus/menuItem'
import {ReactComponent as SearchIcon} from 'images/icons/search.svg'
import {ReactComponent as BurgerIcon} from 'images/icons/Burger.svg'
import {ReactComponent as CloseIcon} from 'images/icons/Cross.svg'

const HeaderContent = (props) => {
  const [query, setQuery] = useState('')
  const updateQueryText= (query) => {
    setQuery(query)
    // if more than 3 char search
  }
  const mainMenuEntries = useMainMenu()
  return (
    <> 
      <div className={HeaderStyles.content}>
        {mainMenuEntries && mainMenuEntries.length > 0 &&
         <nav id="primaryNav" className={`${HeaderStyles.menu}`}>
           {
             mainMenuEntries.map(entry =>{
               return (
                 <DropDownMenu
                   key={entry.slug}
                   entry={entry}
                   className={HeaderStyles.menuItem}
                 />
               )
             })}
         </nav>
        }
        <div className={HeaderStyles.search}>
          <SearchBar query={query} setQuery={setQuery}/>
        </div>
      </div>
      <div className={HeaderStyles.footer}>
        <TopToolbar/>
      </div>
    </>
  );
}


export default (props) => {
  const [isOpen, setOpen] = React.useState(false)
  return (
    <div className={`${HeaderStyles.wrapper} ${isOpen?HeaderStyles.open:''}`}>
      <div className={HeaderStyles.header}>
        <Link to="/search">
          <button id="header__mobile__searchbutton">
            <SearchIcon/>
          </button>
        </Link>
        <SiteLogo className={HeaderStyles.logo}/>
        <button onClick={()=>setOpen(!isOpen)}>
          {isOpen ?
           <CloseIcon/>
           :
           <BurgerIcon/>}
        </button>
      </div>
    {isOpen &&
     <HeaderContent/>
    }
    </div>
  )
}
