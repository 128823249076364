import React from 'react';
import {useStaticQuery, graphql} from 'gatsby'

export const useSocialLinks =  (props) => {
  const data = useStaticQuery(graphql `
    query SocialLinksQuery {
      wordpressAcfOptions{
        options{
          social_networks{
            network
            url
          }
        }
      }
    }
    `)
  
  const links = data.wordpressAcfOptions.options.social_networks
  return links
}
