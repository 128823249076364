export const buildHierarchy = (collection, key, parent_key) => {
  let index = {};
  collection.map(i=>{
      i.items=[]
      return i
  })
    .forEach(i =>{
      index[i[key]] = i;
    });

  let roots = collection.filter(i=>i[parent_key] === 0);
  const ordered_roots =[...roots];
  while(roots.length){
    const root = roots.shift()
    root.items = []
    collection
        .filter(i=>i[parent_key]===root[key])
        .map(i=>index[i[key]])
        .forEach(i=>{
          roots.push(i);
          index[root[key]].items.push(index[i[key]])
      });
  }
  const tmp = Object.values(index).filter(i=>i[parent_key] === 0)
  let _res = [];
  for( const r of ordered_roots){
    _res.push(index[r[key]])
  }
  return _res;
}

export  const sliceExcerpt = (text, max_length)=>{
  const ml = max_length?max_length:100
  return text && ml<text.length? text.slice(0, ml)+'...':text

}
