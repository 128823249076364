import React from 'react'
import {Link} from 'components/link'
import {useFooterMenu} from 'components/menus'
import footerStyles from './footer.module.css'

export default (props) => {
  const items = useFooterMenu()
  return(
    <div className={footerStyles.menu}>
    {items.length >0 && items.map((item, index)=>(
      <Link
        key={item.url}
        to={item.url}
        className={footerStyles.menuitem}>
        <span>{item.title}</span>
      </Link>
    )) }
  </div>
  )
}
