import React from 'react'
import {ReactComponent as ChevronIcon} from 'images/icons/arrow.svg'
import {ContentCard} from './card'


export const PublicationCard =  (props) => (
  <ContentCard
    {...props}
    icon={(<ChevronIcon className="fill-current text-iconblue"/>)}
  />
)




