import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {Link} from 'components/link'
import Img from 'gatsby-image'
import footerStyles from './footer.module.css'
export default (props) => {
  const {data} = useStaticQuery(graphql`
    query footerMenuithIcons{
      data:wordpressAcfOptions{
        options{
          footer_extra_menu{
            link:url {
              title
              url
              target
            }
            image {
              localFile{
                childImageSharp{
                  fixed( height:32){
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
`)
  const items = data.options.footer_extra_menu
  return (
    <div  className={footerStyles.menuicon}>
      {items.length >0 && items.map(item =>(
        <Link to={item.link.url} target={item.link.target} className={footerStyles.menuiconitem}>
          <>
            {item.image && item.image.localFile && item.image.localFile.childImageSharp &&
             <Img fixed={item.image.localFile.childImageSharp.fixed} />
            }
            {item.link.title && item.link.title!=="" && 
             <span  dangerouslySetInnerHTML={{__html:item.link.title}}/>
            }
            
          </>
        </Link>
      ))}
    </div>
  )
}
