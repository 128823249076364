import React from 'react'
import DesktopHeader from './desktop'
import MobileHeader from './mobile'
import MediaQuery from 'react-responsive'


export default (props) => {
  return (
    <header id="siteHeader" className={props.className} style={{zIndex: 50}}>
      <MediaQuery query="(max-width: 1024px)">
        <MobileHeader/>
      </MediaQuery>
      <MediaQuery query="(min-width: 1025px)">
        <DesktopHeader noSearchButton={props.noSearchButton}/>
      </MediaQuery>
    </header>
  )
}
