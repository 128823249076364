import React from 'react'

import {PublicationCard} from './publication'
import {MediaCard} from './media'


export default (props) => (
  <>
  {props.isDownloadable
   ?
   <MediaCard {...props} />
   :
   <PublicationCard {...props}/>
  }
  </>
)
