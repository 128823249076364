import React from 'react'
import SiteLogo from 'components/sitelogo'
import {useMainMenu} from 'components/menus'
import MegaMenu from 'components/menus/megamenu'
import {TopToolbar} from 'components/toolbars'
import {SearchWidget} from 'components/search'
import HeaderStyles from './header.module.css'

const HeaderContent =  (props) => {
  const entries = useMainMenu()
  
  return (
    <>
      <div className={HeaderStyles.header}>
        <div className={HeaderStyles.logo}>
          <SiteLogo/>
        </div>
        <MegaMenu drawerId="HeaderDrawer" items={entries}/>
        <SearchWidget noSearchButton={props.noSearchButton}/>
      </div>
    </>
  );
}

export default (props) => {
  return(
    <>
    <TopToolbar/>
    <div className={`${HeaderStyles.wrapper} shadow`}>
      <HeaderContent noSearchButton={props.noSearchButton}/>
      <div className={HeaderStyles.portalContainer}>
        <div id="HeaderDrawer" className={HeaderStyles.drawer}/>
      </div>

    </div>
    </>
  )
}
