import React from 'react'
import footerStyles from './footer.module.css'
import Logo from 'components/sitelogo'
import Menu from './menu'
import MenuWithIcons from './menuWithicons'
import {SocialLinksBar} from 'components/social'
import {BottomToolbar} from 'components/toolbars'
import ContactInfos from './contact-info'
export default (props) => {
  return (
    <div className={`${footerStyles.footer}`}>
      <div className="container flex flex-col items-center py-30px">
        <div className="mb-5">
          <Logo/>
        </div>
        <div className="mb-5">
          <Menu/>
        </div>
        <div className="mb-5">
          <MenuWithIcons/>
        </div>
        <div className="mb-6 text-gray-200 text-2xs">
          <ContactInfos/>
        </div>
        <div className=" text-gray-200 text-2xl">
          <SocialLinksBar/>
        </div>

      </div>
      <BottomToolbar/>
    </div>
  )
}
