import React from 'react'
import toolbarStyle from './toolbar.module.css'
import {SocialLinksBar} from 'components/social'
import {useBottomToolbarMenu} from 'components/menus'
import {Link} from 'components/link'
import {MediaQuery} from 'react-responsive'

export const BottomToolbar =  (props) => {
  const menu = useBottomToolbarMenu();
  return(
    <>
      <MediaQuery query="(min-width: 768px)">
        <div id="bottomtoolbar" className={`${toolbarStyle.bottom_toolbar}`}>
          <div id="copyright">
            <span>@Euroseeds</span>
          </div>
          <div id="bottomtoolbar_menu" className={toolbarStyle.bottom_toolbar__menu}>
            {menu.map((item, index)=><Link to={item.url} key={`${item.url}-${index}`} >{item.title}</Link>)}
          </div>
          <div>
            <span>Website by</span>  <Link to="https://digit.ag" target="__blank">DIGITAG</Link>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery query="(max-width: 767px)">
        <div id="bottomtoolbar" className={`${toolbarStyle.bottom_toolbar}`}>
          <div id="bottomtoolbar_menu" className={toolbarStyle.bottomToolbarMobileMenu}>
            {menu.map((item, index)=><Link to={item.url} key={`${item.url}-${index}`} >{item.title}</Link>)}
          </div>
          <div className={toolbarStyle.bottomToolbarMobileBottomMenu}>
            <div id="copyright">
              <span>@Euroseeds</span>
            </div>
            <div>
              <span>Website by</span>  <Link to="https://digit.ag" target="__blank">DIGITAG</Link>
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
)
}
