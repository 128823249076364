
import {useStaticQuery, graphql} from "gatsby"

export const useBottomToolbarMenu = () => {
  const {menu} = useStaticQuery(graphql`
    query BottomToolbarMenuItems{
      menu:wordpressWpApiMenusMenusItems(name:{eq:"bottom_toolbar"}){
        items{
          title
          url
          object_slug
          wordpress_parent
          wordpress_id
        }
      }
    }

    `)
  if(menu && menu.items){
    return menu.items
  }
  
  return [];
}

