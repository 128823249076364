import React from 'react'

import {Link} from '../link'
import Styles from './breadcrumbs.module.css'


export const Breadcrumbs = ({ancestors}) => (
  <div className={Styles.container}>

    {ancestors && ancestors.length >1 && ancestors.map((ancestor, index)=> (
      <div className={Styles.breadcrumb}>
        {index !== 0 &&
          <div className={Styles.separator}>&gt;</div>
        }
        {
          ancestor.link ?
          <Link to={ancestor.link} className={Styles.ancestor} activeClassName={Styles.actualPage}>{ancestor.label}</Link> :
          <div>{ancestor.label}</div>
        }
      </div>
      ))}
  </div>
)