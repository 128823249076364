import React from 'react'
import {Link} from 'components/link'
import  Logo from "../images/logos/Euroseeds.svg"
export default (props) => {
  return (
    <Link to='/' >
      <img className={props.className} src={Logo} alt="Euroseeds logo"/>
    </Link>
  )
}
