import React, {useState} from 'react'
import widgetStyle from './searchwidget.module.css'
import {ReactComponent as SearchIcon} from 'images/icons/search.svg'
import {ReactComponent as CloseIcon} from 'images/icons/Cross.svg'
import {InputFieldWithoutWrapper} from 'components/gravityforms/fields/input'
import {Button} from 'components/buttons'

import { navigate } from '@reach/router';


const InputField = (props) => {
  return (
    <input
      type={props.type}
      placeholder={props.placeholder}
      style={props.style}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      className="input appearance-none bg-transparent border-b border-aubergine w-full  text-grey-darker mr-3  leading-tight focus:outline-none italic pl-1"
    />
  )
}

export const SearchWidget = (props) => {
  const [open, setOpen] = React.useState(false)

  return (
    <div className={widgetStyle.searchwidget} style={{visibility: props.noSearchButton ? 'hidden' : ''}}>
      <button onClick={props.noSearchButton || (()=> setOpen(!open))}>{open ? <CloseIcon /> : <SearchIcon />}</button>
      {open &&
       <div className={widgetStyle.expandedSearch}>
         <SearchBar
          smallButton={true}
           {...props}
           onClickHandler={(query)=>{navigate(`/search?q=${encodeURIComponent(query)}`)}}
         />
        </div>
      }
    </div>
  )
}

export const SearchBar = (props) => {
  const [query, setQuery] = useState(props.defaultValue?decodeURIComponent(props.defaultValue):'')
  const submitSearch = (e) => {
    e.preventDefault()
    props.onClickHandler(query)
  }
    return (
      <div >
        <form className={widgetStyle.searchBar} onSubmit={submitSearch}>
          <SearchIcon className={widgetStyle.icon}/>
          <InputField type="text" onChange={e=>{setQuery(e.target.value)}} value={query}/>
          <button id="SearchWidget__button" className={props.smallButton ? widgetStyle.button : widgetStyle.buttonBig} disabled={!(query && query.length>0)} >SEARCH</button>
        </form>
      </div>
    )
  }
