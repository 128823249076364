import React from 'react'
import ReactDOM from 'react-dom'
import megamenuStyles from './megamenu.module.css'
import {Link} from 'components/link'


class SubMenuPortal extends React.PureComponent {
  constructor(props){
    super(props)
    this.rootEl = document.getElementById("HeaderDrawer")
  }

  render(){
    if(this.rootEl){
      return ReactDOM.createPortal(this.props.children, this.rootEl)
    }
    return (<></>)
  }
}

class SubSubMenuPortal extends React.PureComponent {
  constructor(props){
    super(props)
    this.rootEl = document.getElementById("subsubmenu")
  }

  render(){
    if(this.rootEl){
      return ReactDOM.createPortal(this.props.children, this.rootEl)
    }
    return (<></>)
  }
}

const MegaMenuItem = (props) => {
  const depth = props.depth?props.depth:0
  return(
    <Link to={props.url} key={props.url} className={megamenuStyles.item}>
      <span dangerouslySetInnerHTML={{__html:props.title}} />
    </Link>
  )
}


function withMegamenuLogic(WrappedMenuItem, WrappedSubMenu, TargetPortal, depth){
  return class  extends React.Component{
    constructor(props){
      super(props)
      this.drawer = null
      this.state= {
        activeEntry: null,
        isOpen: false,
        mouseInMenu: false,
        mouseInSubMenu: false,
        activeIndex: null,
        menuOffset: 0

      }
      this.closeTimer = null
      this.pendingState = {}
      this.depth = depth?depth:0
      this.itemRefs = []
    }

    updateDrawerState = (state) => {
      if(this.closeTimer){
        clearTimeout(this.closeTimer)
      }
      const newState = {...this.state, ...state}
      if(newState.mouseInMenu || newState.mouseInSubMenu){
        this.setState({
          ...newState,
          isOpen: true
        })
      }
    }
    closeDrawer = () => {
      this.setState({
        mouseInSubMenu: false,
        mouseInMenu: false,
        activeEntry: null,
        isOpen: false
      })
    }
    componentWillUnmount(){
      if(this.closeTimer){
        clearTimeout(this.closeTimer)
      }
      document.getElementById('siteHeader').removeEventListener('mouseleave', this.closeDrawer)
    }

    componentDidMount(){
      //close if we leave header
      document.getElementById('siteHeader').addEventListener('mouseleave', this.closeDrawer )
      for(let i = 0; i < this.props.items.length; i++) {
        this.itemRefs[i] = React.createRef();
      }
    }

    componentDidUpdate(prevProps) {
      const prevItems = prevProps.items;
      const actualItems = this.props.items;
      if(prevItems.length !== actualItems.length) {
        this.setState({activeEntry: null})
        return;
      } else {
        for(let i =0; i<actualItems.length; i++) {
          if(prevItems[i].wordpress_id !== actualItems[i].wordpress_id) {
            this.setState({activeEntry: null})
            return;
          }
        }
      }
    }

    render(){
      const {pathname}  = window.location
      let actualPage = pathname.split('/')[1]
      if(!actualPage) {
        actualPage='dontMatchWithAnything'
      }

      return(
        <nav
          id={this.props.htmlId}
          className={`${megamenuStyles.megamenu} ${megamenuStyles['depth'+this.depth]}`}
          onMouseEnter={()=>{this.updateDrawerState({mouseInMenu:true})}}
          onMouseLeave={()=>{this.updateDrawerState({ mouseInMenu:false})}}
        >

          {this.props.items && this.props.items.length > 0 && this.props.items.map((entry, index) => (

            <div key={`${entry.url}-${index}`}
                 className={`${megamenuStyles.entry} ${entry.url.split('/').slice(3).join('/').includes(actualPage)?megamenuStyles.active:''}`}
              onMouseEnter={(e)=>{
                this.updateDrawerState({
                  activeEntry: entry,
                  activeIndex: index,
                  mouseInMenu: true,
                  menuOffset: e.target.offsetLeft
                })
              }}

              ref={this.itemRefs[index]}
              >
              <WrappedMenuItem
                {...entry}
                depth={depth}

              />
            </div>
          )) }
          {this.state.activeEntry && this.state.activeEntry.items && this.state.activeEntry.items.length >0 && this.state.isOpen &&
           <TargetPortal >
             <WrappedSubMenu
               items={this.state.activeEntry.items}
               onMouseEnter={()=>{this.updateDrawerState({mouseInSubMenu:true})}}
               onMouseLeave={()=>{this.updateDrawerState({mouseInSubMenu:false})}}
               offset={this.state.menuOffset}
               />
           </TargetPortal>
          }
        </nav>
      )
    }
  }
}

const SubSubMenu = (props) => {
  return (
    <div
      className={`${megamenuStyles.megamenu} ${megamenuStyles.depth2}`}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.items && props.items.map((entry, index) => (
        <MegaMenuItem
          key={`${entry.url}-${index}`}
          {...entry}
          className={megamenuStyles.item}
        />
      ))}
    </div>
  )
}


const SubMenu = withMegamenuLogic(
  MegaMenuItem,
  SubSubMenu,
  SubSubMenuPortal,
  1
)

const SubMenuContainer = (props) => {
  return(
    <div
      style={{marginLeft: props.offset}}
      className="container flex w-screen mt-5 mx-auto pb-5"
      onMouseEnter={()=>{ props.onMouseEnter()}}
      onMouseLeave={()=>{ props.onMouseLeave() }}
    >
      <SubMenu {...props} className=""/>
      <div id="subsubmenu"/>
    </div>
  )
}


const RootMenu = withMegamenuLogic(
  MegaMenuItem,
  SubMenuContainer,
  SubMenuPortal
)

export default RootMenu
